import React from 'react'
import Img from 'gatsby-image'

export default ({ title, children, image, ...props }) => {
  return (
    <div className="block-image">
      <div className="block-image__image">
        <Img fluid={image.childImageSharp.fluid} />
      </div>
      <div className="block-image__title">{title}</div>
      <div className="block-image__content">{children}</div>
    </div>
  )
}
