import React from 'react'
import { graphql } from 'gatsby'
import { FormattedMessage } from 'react-intl'
import PageSummary from '../../../components/PageSummary'
import Hr from '../../../components/Hr'
import Grid, { Cell } from '../../../components/Grid'
import MarkdownBlockFromTranslation from '../../../components/MarkdownBlock'
import PageLayout from '../../../components/layout'
import BlockWithImage from '../../../components/BlockWithImage'
import injectNestedIntlProvider from '../../../components/NestedIntlProvider'

const Get1BikePage = ({ data }) => (
  <FormattedMessage id="pages.get1bike.title">
    {title => (
      <PageLayout
        title={title}
        pageSummary={
          <PageSummary
            title={<FormattedMessage id="pages.get1bike.title" />}
            lead={<FormattedMessage id="pages.get1bike.lead" />}
            imageFluid={data.summaryImage.childImageSharp.fluid}
          />
        }
        pageClassName="page--get1bike"
      >
        <MarkdownBlockFromTranslation
          className="page__description"
          id="pages.get1bike.description"
        />

        <Hr />

        <Grid className="mdc-layout-grid--portfolio">
          <Cell className="mdc-layout-grid__cell--span-4 mdc-layout-grid__cell--span-4-desktop">
            <BlockWithImage
              title={<FormattedMessage id="pages.get1bike.enhancement_title" />}
              image={data.get1bike_progressive}
            >
              <FormattedMessage id="pages.get1bike.enhancement_desc" />
            </BlockWithImage>
          </Cell>

          <Cell className="mdc-layout-grid__cell--span-4 mdc-layout-grid__cell--span-4-desktop">
            <BlockWithImage
              title={<FormattedMessage id="pages.get1bike.responsive_title" />}
              image={data.get1bike_responsive}
            >
              <FormattedMessage id="pages.get1bike.responsive_desc" />
            </BlockWithImage>
          </Cell>

          <Cell className="mdc-layout-grid__cell--span-4 mdc-layout-grid__cell--span-4-desktop">
            <BlockWithImage
              title={<FormattedMessage id="pages.get1bike.installable_title" />}
              image={data.get1bike_installable}
            >
              <FormattedMessage id="pages.get1bike.installable_desc" />
            </BlockWithImage>
          </Cell>
        </Grid>
      </PageLayout>
    )}
  </FormattedMessage>
)

export default injectNestedIntlProvider(Get1BikePage)

export const query = graphql`
  query($language: String!) {
    summaryImage: file(relativePath: { eq: "bck-get1bike.jpg" }) {
      ...SummaryImage
    }
    backend: file(relativePath: { eq: "get1bike/dublin.png" }) {
      ...Thumbnail
    }
    get1bike_progressive: file(
      relativePath: { eq: "get1bike/get1bike_progressive.png" }
    ) {
      ...Thumbnail
    }
    get1bike_responsive: file(
      relativePath: { eq: "get1bike/get1bike_responsive.png" }
    ) {
      ...Thumbnail
    }
    get1bike_installable: file(
      relativePath: { eq: "get1bike/get1bike_installable.png" }
    ) {
      ...Thumbnail
    }
    messages: allKeyValue(
      filter: {
        file: { relativeDirectory: { eq: "pages" }, name: { eq: $language } }
        key: {
          regex: "/^pages\\.get1bike/"
        }
      }
    ) {
      edges {
        node {
          key
          value
        }
      }
    }
  }
`
